// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-album-js": () => import("./../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acercade-js": () => import("./../src/pages/acercade.js" /* webpackChunkName: "component---src-pages-acercade-js" */),
  "component---src-pages-aviso-privacidad-js": () => import("./../src/pages/aviso-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-js" */),
  "component---src-pages-certificaciones-js": () => import("./../src/pages/certificaciones.js" /* webpackChunkName: "component---src-pages-certificaciones-js" */),
  "component---src-pages-foraneas-js": () => import("./../src/pages/foraneas.js" /* webpackChunkName: "component---src-pages-foraneas-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multimedia-js": () => import("./../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-servicios-js": () => import("./../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sucursales-js": () => import("./../src/pages/sucursales.js" /* webpackChunkName: "component---src-pages-sucursales-js" */),
  "component---src-pages-terminos-politicas-js": () => import("./../src/pages/terminos-politicas.js" /* webpackChunkName: "component---src-pages-terminos-politicas-js" */),
  "component---src-pages-terminos-uso-js": () => import("./../src/pages/terminos-uso.js" /* webpackChunkName: "component---src-pages-terminos-uso-js" */),
  "component---src-pages-tuxtla-gutierrez-js": () => import("./../src/pages/tuxtla-gutierrez.js" /* webpackChunkName: "component---src-pages-tuxtla-gutierrez-js" */)
}

